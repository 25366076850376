import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import ScrollAnimation from 'react-animate-on-scroll'
import { useMediaQuery } from 'react-responsive'
import "animate.css/animate.min.css"
//import Video from '../components/Video'
import HomeGif from '../img/HomePageGIF_Solution.gif'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

import Layout from '../components/Layout'


export const IndexPageTemplate = ({
  heading,
  //videoHero,
  mobileHero
}) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })

  return (
    <div>
      <div
        className="margin-top-0">
        {isDesktopOrLaptop &&
          <ScrollAnimation animateIn="animate__fadeIn" offset={0}>
            {/* <Video videoSrcURL={videoHero} /> */}
            <img src={HomeGif} alt="Babes in a Meeting" />
          </ScrollAnimation>
        }
        {isTabletOrMobileDevice &&
          <ScrollAnimation animateIn="animate__fadeIn" offset={0}>
            <PreviewCompatibleImage imageInfo={mobileHero} />
          </ScrollAnimation>
        }
      </div>
      <section className="section">
        <div className="container">
          <ScrollAnimation animateIn="animate__fadeInUp" offset={0} duration={0.7} delay={1000}>
            <div className="has-text-centered">
              <h1 className="mb-6 has-text-info" dangerouslySetInnerHTML={{ __html: heading }} />
              <Link to="/about" className="btn btn-one mt-6">TAKE A LOOK AT OUR APPROACH</Link>
            </div>
          </ScrollAnimation>
        </div>
      </section>
    </div>
  )
}

IndexPageTemplate.propTypes = {
  heading: PropTypes.object,
  videoHero: PropTypes.object,
  mobileHero: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        heading={frontmatter.heading}
        videoHero={frontmatter.videoHero}
        mobileHero={frontmatter.mobileHero}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        heading
        mobileHero {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
      }
    }
  }
`
